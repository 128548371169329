<template>
  <div v-if="canEdit || isAuth" class="bg-white rounded-10 p-8">
    <div class="flex items-center justify-between">
      <h4>{{ $t('Devoted.Request history') }}</h4>
      <div class="text-body3 underline text-blue-200 hover:no-underline cursor-pointer">
        {{ $t('Devoted.Review') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DevotedPageSecurity',
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    isAuth: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
